import { useCallback, useEffect, useRef, useState } from 'react';
import HomeNoContentIllustration from 'assets/illustrations/home-no-content-illustration.png';
import Button from 'components/button/Button';
import { useNavigate } from 'react-router-dom';
import {
  API_BASE_URL,
  ASSETS,
  DELETE_ASSET,
  FAVORITE_ASSET,
  GET_FABRIC_TYPES,
} from 'utils/constants/api';
import useFetch from 'utils/hooks/useFetch';
import AppImage from 'components/image/base/AppImage';
import EllipseIcon from 'assets/icons/EllipseIcon';
import MoreIcon from 'assets/icons/MoreIcon';
import FavoriteIcon from 'assets/icons/FavoriteIcon';
import SearchIcon from 'assets/icons/SearchIcon';
import PlusIcon from 'assets/icons/PlusIcon';
import Pagination from 'components/pagination/Pagination';
import usePagination from 'utils/hooks/usePagination';
import CloseIcon from 'assets/icons/CloseIcon';
import AppDropdown from 'components/dropdown/base/AppDropdown';
import DeleteModal from 'components/modal/DeleteModal';
import Search from 'components/input/Search';
import ChevronLeftIcon from 'assets/icons/ChevronLeftIcon';
import ChevronRightIcon from 'assets/icons/ChevronRightIcon';

function Home() {
  const categoryRef = useRef(null);
  const { data: assets, loading: loadingAssets, fetch: getAssets } = useFetch(ASSETS);
  const { fetch: deleteAsset } = useFetch(DELETE_ASSET);
  const { isSubmitting: submittingFavorite, fetch: favoriteAsset } = useFetch(FAVORITE_ASSET);
  const { data: fabricTypes, fetch: getFabricTypes } = useFetch(GET_FABRIC_TYPES);

  const navigate = useNavigate();
  const { currentPage, prevPage, nextPage, changePage, itemsPerPage } = usePagination({
    itemsPerPage: 12,
    dataLength: assets?.total,
  });

  const [search, setSearch] = useState('');
  const [allowSearch, setAllowSearch] = useState(false);
  const [dropdown, setDropdown] = useState(null);
  const [deleteModal, setDeleteModal] = useState({ cd: null, isOpen: false });
  const [selectedCategory, setSelectedCategory] = useState(null);

  const handleGetAssets = useCallback(
    async ({ search = '', filter = {}, sort = ['updated_dt', 'desc'] } = {}) => {
      await getAssets({
        data: {
          page: currentPage + 1,
          page_size: itemsPerPage,
          search,
          filter,
          sort,
        },
      });
    },
    [currentPage, getAssets, itemsPerPage]
  );

  const handleSearch = useCallback(() => {
    handleGetAssets({ search });
  }, [handleGetAssets, search]);

  const handleChangeSearch = useCallback((e) => {
    setSearch(e.target.value);
    setAllowSearch(true);
  }, []);

  const handleClearSearch = useCallback(() => {
    setSearch('');
    handleGetAssets();
  }, [handleGetAssets]);

  const handleDeleteAsset = useCallback(
    async (fabric_cd) => {
      await deleteAsset({
        data: {
          fabric_cd,
        },
      });
      setDeleteModal({ cd: null, isOpen: false });
      await handleGetAssets();
    },
    [deleteAsset, handleGetAssets]
  );

  const handleFavoriteAsset = useCallback(
    async (fabric_cd, favorite) => {
      await favoriteAsset({
        data: {
          fabric_cd,
          favorite,
        },
      });
      await handleGetAssets();
    },
    [favoriteAsset, handleGetAssets]
  );

  const handleSelectCategory = useCallback(
    (category) => {
      setSelectedCategory(category);
      handleGetAssets({ search: category });
    },
    [handleGetAssets]
  );

  const scrollCategory = useCallback((direction) => {
    if (categoryRef.current) {
      categoryRef.current.scrollBy({ left: direction === 'left' ? -200 : 200, behavior: 'smooth' });
    }
  }, []);

  useEffect(() => {
    handleGetAssets();
  }, [handleGetAssets]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (allowSearch) handleSearch();
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [allowSearch, handleSearch, search]);

  useEffect(() => {
    getFabricTypes();
  }, [getFabricTypes]);

  return (
    <div className="app-main-container">
      {assets?.data?.length > 0 || allowSearch ? (
        <div className="flex flex-col gap-6">
          <div className="space-y-5">
            <p className="font-bold text-app-white-title text-xl sm:text-2xl">Generated List</p>
            <div className="w-full h-[1px] bg-[#555656]" />
          </div>
          <div className="flex flex-col sm:flex-row justify-between gap-5">
            <Search
              onChange={handleChangeSearch}
              onClear={handleClearSearch}
            />
            <div className="flex gap-4">
              <Button
                className="text-sm sm:text-base flex-1 sm:flex-auto"
                onClick={() => navigate('/generator')}
                icon={
                  <PlusIcon className="fill-app-black-primary w-5 sm:w-6 h-5 sm:h-6 text-app-white-primary" />
                }
                text="Create New"
                type="primary"
              />
            </div>
          </div>
          <div className="relative flex items-center">
            <button
              className="hidden xl:block absolute left-0 bg-app-black-secondary h-full"
              onClick={() => scrollCategory('left')}
            >
              <ChevronLeftIcon className="w-6 h-6 fill-app-icon-primary" />
            </button>
            <div
              ref={categoryRef}
              className="flex gap-2 overflow-x-auto overflow-y-hidden -mx-4 xl:mx-0 px-4 xl:px-8 no-scrollbar py-1"
            >
              <button
                className={`text-sm sm:text-base grow-0 shrink-0 basis-[72px] border  p-3 whitespace-nowrap w-full rounded ${
                  selectedCategory === null ? 'bg-app-brand border-app-brand' : 'border-[#5A5B5B]'
                }`}
                onClick={() => handleSelectCategory(null)}
              >
                All
              </button>
              {fabricTypes?.data?.map((type, index) => (
                <button
                  key={index}
                  className={`text-sm sm:text-base grow-0 shrink-0 basis-[72px] border border-[#5A5B5B] p-3 whitespace-nowrap w-full rounded ${
                    selectedCategory === type?.nm
                      ? 'bg-app-brand border-app-brand'
                      : 'border-[#5A5B5B]'
                  }`}
                  onClick={() => handleSelectCategory(type?.nm)}
                >
                  {type?.nm}
                </button>
              ))}
            </div>
            <button
              className="hidden xl:block absolute right-0  bg-app-black-secondary h-full"
              onClick={() => scrollCategory('right')}
            >
              <ChevronRightIcon className="w-6 h-6 fill-app-icon-primary" />
            </button>
          </div>
          <div className="flex justify-center">
            {assets?.data?.length > 0 ? (
              <div className="w-full grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
                {assets?.data.map((asset, index) => {
                  return (
                    <div
                      key={index}
                      className="flex justify-center"
                    >
                      <div className="bg-app-black-primary rounded-xl p-4 flex flex-col items-center gap-4 relative w-[300px]">
                        <a href={`/generator?f_cd=${asset?.cd}`}>
                          <AppImage
                            src={API_BASE_URL + asset?.url}
                            alt={asset?.title}
                            className="w-48 h-48 rounded-2xl"
                          />
                        </a>
                        <a
                          href={`/generator?f_cd=${asset?.cd}`}
                          className="flex-wrap text-sm sm:text-base w-full break-words"
                        >
                          {asset?.title}
                        </a>
                        <div className="w-full flex items-center justify-between gap-2 mt-auto">
                          <div className="flex items-center gap-2">
                            <p className="text-sm sm:text-base">{asset?.fabric_type}</p>
                            <div>
                              <EllipseIcon className="w-0.5 h-0.5 fill-app-icon-primary" />
                            </div>
                            <div
                              className={`rounded py-1 px-2 text-[10px] sm:text-xs border ${
                                asset?.status === 'Draft'
                                  ? 'border-[#555656] bg-app-black-secondary '
                                  : 'border-app-brand-text bg-app-brand text-[#C8E4DB]'
                              }`}
                            >
                              {asset?.status}
                            </div>
                          </div>
                          <AppDropdown
                            buttonComponent={
                              <button
                                className="flex gap-2 items-center text-sm"
                                onClick={() =>
                                  setDropdown((prev) => (prev === index ? null : index))
                                }
                              >
                                <MoreIcon />
                              </button>
                            }
                            isOpen={dropdown === index}
                            setOpen={() => setDropdown(null)}
                          >
                            <div className="bg-app-black-secondary p-3 text-sm min-w-28">
                              <button
                                className="flex items-center space-x-2 w-full"
                                onClick={() => {
                                  setDeleteModal({ cd: asset?.cd, isOpen: true });
                                  setDropdown(null);
                                }}
                              >
                                <p>Delete</p>
                              </button>
                            </div>
                          </AppDropdown>
                        </div>
                        <button
                          className="absolute top-4 right-4"
                          disabled={submittingFavorite}
                          onClick={() => handleFavoriteAsset(asset?.cd, !asset?.favorite)}
                        >
                          <FavoriteIcon
                            className={`h-5 sm:h-6 w-5 sm:w-6 ${
                              asset?.favorite ? 'fill-red-600' : 'stroke-[#F1F1F1]'
                            }`}
                          />
                        </button>
                      </div>
                    </div>
                  );
                })}
              </div>
            ) : (
              <div className="flex flex-col items-center space-y-8">
                <img
                  className="min(w-[300px], 100%)"
                  src={HomeNoContentIllustration}
                  alt="No Content Illustration"
                />
                <div className="space-y-2 text-center">
                  <p className="text-[28px] font-bold">No Matching Results</p>
                  <p>Please refine your search or adjust the filters and try again.</p>
                </div>
              </div>
            )}
          </div>
          {assets?.data?.length > 0 && (
            <Pagination
              totalPages={assets?.page_count}
              currentPage={currentPage}
              changePage={changePage}
              prevPage={prevPage}
              nextPage={nextPage}
            />
          )}
        </div>
      ) : loadingAssets ? (
        <div className="flex flex-col gap-6">
          <div className="space-y-5">
            <p className="font-bold text-app-white-title text-xl sm:text-2xl">Generated List</p>
            <div className="w-full h-[1px] bg-[#555656]" />
          </div>
          <div className="flex flex-col sm:flex-row justify-between gap-5">
            <div className="relative border border-[#555656] flex items-center gap-2 w-full sm:w-80 py-2 px-2 sm:px-3 rounded">
              <SearchIcon className="w-6 h-6 fill-app-icon-primary" />
              <div>Search</div>
              {search && (
                <button>
                  <CloseIcon className="fill-app-white-primary h-6 w-6" />
                </button>
              )}
            </div>

            <div className="flex gap-4">
              {/* <Button
                className="flex-1 sm:flex-auto"
                icon={
                  <div className="w-6 h-6 flex items-center justify-center">
                    <KeyboardArrowDownIcon className="fill-app-brand w-3 h-3" />
                  </div>
                }
                text="Show All"
                type="secondary"
              /> */}
              <Button
                className="flex-1 sm:flex-auto"
                icon={<PlusIcon className="fill-app-black-primary w-6 h-6" />}
                text="Create New"
                type="primary"
              />
            </div>
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
            {Array.from({ length: 8 }).map((_, index) => (
              <div
                key={index}
                className="flex justify-center"
              >
                <div className="bg-app-black-primary rounded-xl p-4 flex flex-col items-center gap-4 relative w-72 animate-pulse">
                  <div className="bg-app-grey-primary w-44 h-44 rounded-2xl" />
                  <div className="space-y-2">
                    <div className="bg-app-grey-primary h-4 w-60 rounded" />
                    <div className="bg-app-grey-primary h-4 w-40 rounded" />
                  </div>
                  <div className="w-full flex items-center justify-between gap-2">
                    <div className="flex items-center gap-2">
                      <div className="bg-app-grey-primary h-4 w-10 rounded" />

                      <div>
                        <EllipseIcon className="w-0.5 h-0.5 fill-app-icon-primary" />
                      </div>
                      <div className="bg-app-grey-primary h-4 w-10 rounded" />
                    </div>
                    <div>
                      <MoreIcon />
                    </div>
                  </div>
                  <div className="absolute top-4 right-4">
                    <FavoriteIcon />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      ) : (
        <div className="flex flex-col items-center space-y-8">
          <img
            className="min(w-[400px], 100%)"
            src={HomeNoContentIllustration}
            alt="No Content Illustration"
          />
          <div className="space-y-2 text-center">
            <p className="text-[32px] font-bold">Can't Find the Fabric Generated</p>
            <p>
              There are no generated textures yet. Let's try this to create your best texture now!
            </p>
          </div>
          <Button
            onClick={() => navigate('/generator')}
            className="min(w-[160px], 100%)"
            text="Let's Do it!"
          />
        </div>
      )}
      <DeleteModal
        onClose={() => setDeleteModal({ cd: null, isOpen: false })}
        onYes={() => handleDeleteAsset(deleteModal?.cd)}
        isOpen={deleteModal.isOpen}
      />
    </div>
  );
}

export default Home;
