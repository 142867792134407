import Cookies from 'js-cookie';
import { jwtDecode } from 'jwt-decode';
import { API_BASE_URL } from 'utils/constants/api';

export const setCookie = (name, value, onSuccess = () => {}) => {
  if (value && value !== undefined) {
    const decodedToken = jwtDecode(value);
    const expirationInSeconds = decodedToken.exp;
    const expirationDate = new Date(expirationInSeconds * 1000);
    Cookies.set(name, value, { expires: expirationDate });
    onSuccess();
  }
};

export const getCookie = (name) => {
  return Cookies.get(name);
};

export const decodeToken = (token) => {
  if (token && token !== 'undefined') {
    return jwtDecode(token);
  } else {
    return null;
  }
};

export async function urlToFile(url, filename, mimeType = 'image/png') {
  try {
    const response = await fetch(url);

    if (!response.ok) {
      throw new Error(`Failed to fetch image: ${response.statusText}`);
    }

    const blob = await response.blob();

    const metadata = { mimeType };

    return new File([blob], filename, metadata);
  } catch (error) {
    console.error('Error fetching the image:', error);
    throw error;
  }
}

export const preloadImages = (imageUrls) => {
  imageUrls.forEach((url) => {
    const img = new Image();
    img.src = url;
  });
};

export const parseUrl = (url) => {
  const base = API_BASE_URL || window.location.origin;

  if (url) {
    return new URL(url, base)?.href;
  } else {
    return base;
  }
};

export const fetchImage = async (path, delay, setLoading) => {
  setTimeout(async () => {
    try {
      await fetch(parseUrl(path));
      setLoading(false);
    } catch (error) {
      const id = setInterval(async () => {
        try {
          await fetch(parseUrl(path));
          setLoading(false);
          clearInterval(id);
        } catch (error) {
          console.error('Error fetching image:', error);
        }
      }, 2500);
    }
  }, delay * 1000);
};
