import ForgotPassword from 'pages/auth/ForgotPassword';
import Login from 'pages/auth/Login';
import Register from 'pages/auth/Register';
import NotFound from 'pages/error/404';
import Generator from 'pages/generator/Generator';
import Home from 'pages/home/Home';
import Profile from 'pages/profile/Profile';

export const routes = [
  {
    path: '/',
    element: <Home />,
  },
  {
    path: '/generator',
    element: <Generator />,
  },
  {
    path: '/profile',
    element: <Profile />,
  },
  {
    path: '*',
    element: <NotFound />,
  },
];

export const authRoutes = [
  {
    path: '/login',
    element: <Login />,
  },
  {
    path: '/register',
    element: <Register />,
  },
  {
    path: '/forgot-password',
    element: <ForgotPassword />,
  },
];
