import ArrowLeftIcon from 'assets/icons/ArrowLeftIcon';
import BackButton from 'components/button/BackButton';
import Button from 'components/button/Button';
import AppCard from 'components/card/base/AppCard';
import ResultCard from 'components/card/ResultCard';
import PromptDropdown from 'components/dropdown/PromptDropdown';
import AppImage from 'components/image/base/AppImage';
import SectionLabel from 'components/input/SectionLabel';
import TextArea from 'components/input/TextArea';
import TabHeader from 'components/tab/TabHeader';
import rgbHex from 'rgb-hex';

import { FABRIC_PATTERNS, IS_MOBILE_SCREEN, NUM_OF_OUTPUTS } from 'utils/constants';
import { API_BASE_URL } from 'utils/constants/api';
import { PANTONE_COLORS } from 'utils/constants/pantone';

function Tab2({
  selectedImage,
  setNumOfOutputs,
  numOfOutputs,
  handleGenerateFabrics,
  specificPrompt,
  setSpecificPrompt,
  generatedFabrics,
  selectedGenImage,
  setSelectedGenImage,
  isSubmitting,
  handleChangeTab,
  selectedCustomization,
  handleGenerateMaps,
  fabrics,
  onAddColorToPrompt,
}) {
  const handleContinue = () => {
    handleChangeTab(3);
    handleGenerateMaps(selectedGenImage);
  };
  const disableGenerate =
    !specificPrompt &&
    !selectedCustomization?.patterns?.length > 0 &&
    !selectedCustomization?.colors?.length > 0;

  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 py-8 app-main-container gap-6">
      <AppCard className="w-full space-y-8">
        <TabHeader
          subtitle="Step 2"
          title="Customization Process"
          description="Provide more specific prompts to customize it further. Our AI will refine the fabric based on your inputs."
        />
        <div className="flex-1 space-y-8">
          <div className="flex space-x-3 sm:space-x-4 p-3 border rounded-xl items-center">
            <div className="sm:w-32 h-auto aspect-square bg-app-black-secondary flex items-center justify-center rounded-lg">
              <AppImage
                className="w-full h-full object-cover rounded-lg"
                src={API_BASE_URL + selectedImage?.path}
                alt={selectedImage?.path}
              />
            </div>
            <div className="space-y-4">
              <div className="space-y-2">
                <div className="flex flex-col sm:flex-row items-start sm:items-center gap-2">
                  <p className="font-bold text-sm sm:text-base text-app-white-title">
                    Selected Image
                  </p>
                  <div className="text-xs sm:text-sm bg-app-info border border-[#1489A8] rounded-md px-2 py-1">
                    {fabrics?.fabric_type_nm}
                  </div>
                </div>
                <p className="text-xs sm:text-sm">
                  This is the image you selected for further customization.
                </p>
              </div>
              <button
                className="flex items-center gap-1 border px-2 py-1 rounded-md"
                onClick={onAddColorToPrompt}
              >
                <ArrowLeftIcon className="-rotate-90 fill-app-white-primary w-5 h-5" />
                <p className="text-xs sm:text-sm">Add this color to prompt</p>
              </button>
            </div>
          </div>

          <div className="space-y-2">
            <TextArea
              title="Prompt"
              topDescription="Enter specific prompts and details here to personalize the fabric that you've chosen from our AI-generated options"
              placeholder="Enter your text prompt here"
              onSend={handleGenerateFabrics}
              value={specificPrompt}
              onChange={(e) => setSpecificPrompt(e.target.value)}
              disab
              led={isSubmitting}
            />
            <div className="flex gap-2">
              <PromptDropdown
                title="Color"
                options={PANTONE_COLORS?.map((color) => ({
                  label: `${color.colorName} (${color.name})`,
                  value: `${color.name} (${color.colorName})`,
                  color: `#${rgbHex(color.r, color.g, color.b)}`,
                }))}
                onClickOption={(option) =>
                  setSpecificPrompt(
                    (prev) => prev + `${prev ? `, ${option?.value}` : option?.value}`
                  )
                }
              />
              <PromptDropdown
                title="Pattern"
                options={FABRIC_PATTERNS}
                onClickOption={(option) =>
                  setSpecificPrompt(
                    (prev) =>
                      prev +
                      `${prev ? `, ${option?.label?.toLowerCase()}` : option?.label?.toLowerCase()}`
                  )
                }
                position={IS_MOBILE_SCREEN ? 'right-0' : 'left-0'}
              />
            </div>
          </div>
          <div className="space-y-2 sm:space-y-4">
            <SectionLabel
              title="Number of Outputs"
              description="Set the number of results generated in response to your input. Adjust this to control how many outputs you want to receive"
            />
            <div className="flex space-x-2 sm:space-x-4">
              {NUM_OF_OUTPUTS.map((number, index) => (
                <div
                  key={index}
                  className="text-sm sm:text-base"
                >
                  <input
                    className="hidden peer"
                    type="radio"
                    id={number}
                    name={number}
                    value={number}
                    onClick={() => setNumOfOutputs(number)}
                    checked={numOfOutputs === number}
                    readOnly
                  />
                  <label
                    className={`peer-checked:bg-app-brand peer-checked:border-app-brand flex items-center justify-center w-10 h-10 border cursor-pointer rounded-md text-xs sm:text-sm font-bold`}
                    htmlFor={number}
                  >
                    {number}
                  </label>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="flex justify-between">
          <BackButton onClick={() => handleChangeTab(1)} />
          <Button
            isSubmitting={isSubmitting}
            disabled={disableGenerate}
            onClick={handleGenerateFabrics}
            text="Generate"
          />
        </div>
      </AppCard>
      <ResultCard
        cardTitle="Select one image"
        cardDescription="Select an AI-generated image for creating texture maps that show how the fabric will look."
        images={generatedFabrics?.image_path}
        selectedImage={selectedGenImage}
        setSelectedImage={setSelectedGenImage}
        isSubmitting={isSubmitting}
        handleContinue={handleContinue}
      />
    </div>
  );
}

export default Tab2;
