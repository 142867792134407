import React, { useState } from 'react';
import AppDropdown from './base/AppDropdown';
import SearchIcon from 'assets/icons/SearchIcon';
import CloseIcon from 'assets/icons/CloseIcon';
import PlusIcon from 'assets/icons/PlusIcon';

function PromptDropdown({ className, title, options, onClickOption, position = 'left-0', tabs }) {
  const [search, setSearch] = useState('');
  const [isOpen, setOpen] = useState(false);

  const handleOnClear = () => {
    setSearch('');
  };
  return (
    <AppDropdown
      className={className}
      buttonComponent={
        <button
          className="text-xs sm:text-sm py-2 px-4 sm:px-8 flex items-center space-x-1 rounded-md border border-[#555656]"
          onClick={() => setOpen((prev) => !prev)}
        >
          <PlusIcon className="w-5 h-5 fill-app-white-primary" />
          <p>{title}</p>
        </button>
      }
      isOpen={isOpen}
      setOpen={setOpen}
      position={position}
    >
      <div className="bg-app-black-primary p-2 space-y-3 max-h-80 overflow-hidden mb-8 flex flex-col">
        <p className="px-2">{title}</p>
        <div className="relative border border-[#555656] flex items-center gap-2 sm:w-80 py-1 px-1 sm:px-2 mx-2 rounded">
          <SearchIcon className="w-5 sm:w-6 h-5 sm:h-6 fill-app-icon-primary" />
          <input
            className="text-xs sm:text-sm bg-transparent outline-none flex-1"
            placeholder="Search"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
          {search && (
            <button onClick={handleOnClear}>
              <CloseIcon className="fill-app-white-primary h-6 w-6" />
            </button>
          )}
        </div>
        {tabs && (
          <div className="flex">
            {tabs.map((tab) => (
              <button
                key={tab}
                className="flex-1 text-xs sm:text-sm px-2 py-1 rounded-md border border-[#555656]"
                onClick={() => setSearch(tab)}
              >
                {tab}
              </button>
            ))}
          </div>
        )}

        <div className="grid grid-cols-2 sm:grid-cols-3 gap-3 px-2 overflow-y-auto">
          {(search
            ? options?.filter((option) => option.label.toLowerCase().includes(search.toLowerCase()))
            : options
          )?.map((option, index) => (
            <button
              key={index}
              className={`flex flex-col gap-1 rounded-md ${
                !option?.value?.includes('#') &&
                'bg-app-black-secondary flex justify-center items-center px-3 py-2'
              }`}
              onClick={() => onClickOption(option)}
            >
              {option?.color && (
                <div
                  className="h-8 w-full rounded-md"
                  style={{ backgroundColor: option.color }}
                />
              )}
              <p className="text-xs sm:text-sm text-left">{option?.label}</p>
            </button>
          ))}
        </div>
      </div>
    </AppDropdown>
  );
}

export default PromptDropdown;
