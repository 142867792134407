export const API_BASE_URL = process.env.REACT_APP_API_URL;

export const LOGIN = {
  method: 'post',
  url: '/auth/login',
};

export const REGISTER = {
  method: 'post',
  url: '/auth/register',
};

export const LOGOUT = {
  method: 'post',
  url: '/auth/logout',
};

export const CHANGE_PASSWORD = {
  method: 'post',
  url: '/auth/reset',
};

export const POST_TEXT_MESSAGE = {
  method: 'post',
  url: '/Prod',
};

export const FIND_FABRICS_WITH_TEXT = {
  method: 'post',
  url: '/text/get',
};

export const GENERATE_FABRICS = {
  method: 'post',
  url: '/generate/get',
};

export const GENERATE_MAPS = {
  method: 'post',
  url: '/maps/get',
};

export const FIND_FABRICS_WITH_IMAGE = {
  method: 'post',
  url: '/image/get',
};

export const GET_FABRIC = {
  method: 'get',
  url: '/fabrics/:cd',
};

export const GET_GENERATED_FABRICS = {
  method: 'get',
  url: '/generated-fabrics/:cd',
};

export const GET_HISTORY = {
  method: 'post',
  url: '/fabric-history/get',
};

export const ASSETS = {
  method: 'post',
  url: '/asset-library/get-list',
};

export const SAVE = {
  method: 'post',
  url: '/maps/save',
};

export const DELETE_ASSET = {
  method: 'post',
  url: '/asset-library/delete',
};

export const FAVORITE_ASSET = {
  method: 'post',
  url: '/asset-library/favorite',
};

export const GET_FABRIC_TYPES = {
  method: 'post',
  url: '/fabric-type/get-list',
};

export const GET_USER_COINS = {
  method: 'post',
  url: '/user/get-coin',
};

export const GET_PROFILE = {
  method: 'post',
  url: '/user/me',
};

export const UPDATE_PROFILE = {
  method: 'post',
  url: '/user/update',
};
