import AppImage from 'components/image/base/AppImage';
import React, { useState } from 'react';
import { parseUrl } from 'utils/helpers';

function Final({ maps }) {
  const [selectedImage, setSelectedImage] = useState(maps?.image_path?.[0]);

  return (
    <div className="app-main-container flex flex-col items-center gap-8">
      <div>
        <AppImage
          src={parseUrl(selectedImage?.path)}
          alt={selectedImage?.path}
          className="border w-[280px] h-[280px] sm:w-[500px] sm:h-[500px] rounded-lg object-cover"
        />
      </div>
      <div className="flex gap-4 sm:gap-8 bg-app-black-primary p-4 rounded-2xl overflow-x-auto w-full sm:w-auto">
        {maps?.image_path?.map((image, index) => {
          return (
            <div
              key={index}
              className={`flex-none flex flex-col items-center aspect-square hover:opacity-80 transition-all cursor-pointer rounded-xl p-2 md:p-4 ${
                selectedImage?.path === image?.path
                  ? 'bg-[#09362A] border border-[#006045]'
                  : 'border border-app-white-primary'
              }`}
              onClick={() => setSelectedImage(image)}
            >
              {image?.name && (
                <p className="text-xs sm:text-sm text-center font-semibold mb-1 sm:mb-2">
                  {image?.name}
                </p>
              )}
              <AppImage
                src={parseUrl(image?.path)}
                alt={image?.path}
                className="rounded-lg object-cover w-20 sm:w-28"
              />
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default Final;
